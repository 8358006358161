document.addEventListener('DOMContentLoaded', () => {
  const changeSitePreferencesBtn = document.querySelector('#changeSitePreferencesBtn');
  const selectCountry = document.querySelector('#selectCountry');
  const selectLanguage = document.querySelector('#selectLanguage');
  const selectCurrency = document.querySelector('#selectCurrency');
  const cartContentCurrencyIdContainer = document.querySelector('#cartContentCurrencyIdContainer');
  const cartContentCurrencyId = document.querySelector('#cartContentCurrencyId');
  selectCountry.addEventListener('change', () => updateButtonHrefParam(selectCountry));
  selectCurrency.addEventListener('change', () => updateButtonHrefParam(selectCurrency));
  selectLanguage.addEventListener('change', () => updateButtonHrefLang(selectLanguage));
  function updateButtonHrefParam(selectBox) {
    cartContentCurrencyIdContainer.classList.add('d-none');
    if (parseInt(cartContentCurrencyId.value) !== parseInt(selectBox.value)) {
      cartContentCurrencyIdContainer.classList.remove('d-none');
    }
    const buttonUrl = new URL(changeSitePreferencesBtn.href);
    const urlParams = buttonUrl.searchParams;

    // Update or add the new parameters
    urlParams.set(selectBox.dataset.param, selectBox.value);

    // Update button href
    changeSitePreferencesBtn.href = `${buttonUrl.origin}${buttonUrl.pathname}?${urlParams.toString()}`;
  }
  function updateButtonHrefLang(selectBox) {
    const buttonUrl = new URL(changeSitePreferencesBtn.href);
    const pathParts = buttonUrl.pathname.split('/');

    // Erstat sprogkoden med den valgte værdi
    pathParts[1] = selectBox.value;

    // Sæt den opdaterede sti tilbage til URL’en
    buttonUrl.pathname = pathParts.join('/');

    // Opdater knapens href-attribut med den nye URL
    changeSitePreferencesBtn.href = buttonUrl.toString();
  }
});